import React from 'react';

import { type GetServerSideProps } from 'next';
import Head from 'next/head';

import { navigationPaths } from '@client/constants/navigation';
import PublicRoute from '@client/context/auth/PublicRoute';
import { BootIntercom } from '@client/helpers/intercom/bootIntercom';
import Login from '@client/pages/auth/login';

export const getServerSideProps: GetServerSideProps<{
  redirectUri: string | null;
}> = async context => {
  if (context.query?.cta === 'offer') {
    const { followUpOffer } = navigationPaths.intake;
    return {
      props: {
        redirectUri: followUpOffer,
      },
    };
  }
  return { props: { redirectUri: null } };
};

export default ({ redirectUri }: { redirectUri: string | null }) => (
  <PublicRoute disabledRedirect={true}>
    <BootIntercom>
      <Head>
        <title>Astro - Inloggen</title>
      </Head>
      <Login redirectUri={redirectUri} />
    </BootIntercom>
  </PublicRoute>
);
